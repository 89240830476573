<template>
  <RevButton
    :disabled="disabled"
    full-width="always"
    :loading="loading"
    variant="secondary"
    @click.stop.prevent="addToCartHandler(id)"
  >
    <span class="body-1-bold">{{ text }}</span>
  </RevButton>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'

import { RevButton } from '@ds/components/Button'

import { useAddToCart } from '../../composables/useAddToCart'

defineProps<{
  text: string
  id: string
}>()

const disabled = ref(true)
onMounted(() => {
  disabled.value = false
})

const emit = defineEmits(['add-to-cart'])

const { addToCart, addingToCart } = useAddToCart()

const loading = computed(() => addingToCart.value === 'pending')

async function addToCartHandler(id: string) {
  await addToCart(id)
  emit('add-to-cart', { status: addingToCart.value })
}
</script>
