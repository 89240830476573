export default {
  loading: {
    id: 'spinner',
    defaultMessage: 'Loading',
  },
  alternativeNext: {
    id: 'alternative-next',
    defaultMessage: 'Next',
  },
  alternativePrevious: {
    id: 'alternative-previous',
    defaultMessage: 'Previous',
  },
} as const
