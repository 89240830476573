<template>
  <ul
    v-if="displayedColors.length > 0"
    class="flex list-none items-center justify-center"
    :class="classes[size].gap"
  >
    <li v-for="color in displayedColors" :key="color.name">
      <div
        class="border-action-default-hi rounded-full border"
        :class="classes[size].radius"
        role="img"
        :style="{ background: color.value || color.name }"
        :title="i18n(translations.colorSwatches, { color: color.name })"
      />
    </li>
    <li
      v-if="additionalColorsCount > 0"
      class="text-action-default-hi"
      :class="classes[size].text"
    >
      +{{ additionalColorsCount }}
      <span class="sr-only">{{ i18n(translations.moreColors) }}</span>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { tw } from '@backmarket/utils/string/tw'

import translations from './ColorSwatches.translations'

const i18n = useI18n()

const MAX_COLORS = 4

const props = withDefaults(
  defineProps<{
    colors: Array<{ name: string; value?: string }>
    size?: 'xs' | 'sm'
    maxColors?: number
  }>(),
  {
    size: 'xs',
    maxColors: MAX_COLORS,
  },
)

const classes = {
  xs: {
    radius: tw`w-12 h-12`,
    gap: tw`gap-x-[0.2rem]`,
    text: tw`caption`,
  },
  sm: {
    radius: tw`w-16 h-16`,
    gap: tw`gap-x-8`,
    text: tw`body-2-bold`,
  },
}

const displayedColors = computed(() => props.colors.slice(0, props.maxColors))
const additionalColorsCount = computed(
  () => props.colors.slice(props.maxColors).length,
)
</script>
