import { type ComponentPublicInstance, onMounted, onUnmounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import { useVisitorId } from '@backmarket/nuxt-module-identification/useVisitorId'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { useIntersectionObserver } from '@vueuse/core'

import { saveProductInfos } from '../helpers/recommendationHelper'
import type { Product } from '../models/product'

export type Provider = 'attraqt' | 'algolia' | 'product page'

type TrackingData = Record<string, NonNullable<unknown>>

export function productToTrackingData(
  product: Product,
  extraTrackingData: ExtraTrackingData,
  dealType: 'normal' = 'normal',
): TrackingData {
  const data = {
    averageRate: product.reviewRating?.average,
    brand: product.brand,
    category: product.category,
    stock: product.availableStock,
    currency: product.price?.currency,
    uuid: product.id,
    listingId: product.listingId,
    numberTotalReviews: product.reviewRating?.count,
    price: product.price?.amount,
    variant: product.grade?.value,
    warrantyDuration: product.warrantyDuration,
    name: product.title,
    model: product.model,
    image: product.image,
    color: product.specifications?.color,
    merchantId: product.sellerId,
    list: extraTrackingData.list,
    provider: extraTrackingData.provider,
    position: extraTrackingData.position,
    productComponent: extraTrackingData.productComponent,
    productRecoComponentPosition:
      extraTrackingData.productRecoComponentPosition,
    dealType,
  }

  return Object.fromEntries(
    Object.entries(data).filter(
      (entry): entry is [string, string | number] =>
        entry[1] !== null && entry[1] !== undefined,
    ),
  )
}

export function usePageReference() {
  const { name, params, fullPath } = useRoute()
  const pageNameParam = params.pageName
  const pageName = Array.isArray(pageNameParam)
    ? pageNameParam.join(',')
    : pageNameParam
  const routeName = typeof name === 'symbol' ? name.description : name

  return pageName ?? routeName ?? fullPath
}

export function useListTrackingData({
  blockPosition,
}: {
  blockPosition?: number
}) {
  const pageReference = usePageReference()

  return `${pageReference} ${blockPosition}`
}

export function useImpressionTracking(ms = 1000) {
  const trackingData = new Map<Element, TrackingData>()
  const bucket: Set<TrackingData> = new Set()
  const targets = ref<HTMLElement[]>([])

  const { stop } = useIntersectionObserver(
    targets,
    (entries) => {
      for (const entry of entries) {
        const trackingDataItem = trackingData.get(entry.target)
        if (entry.isIntersecting && trackingDataItem) {
          bucket.add(trackingDataItem)
        }
      }
    },
    { threshold: 0.8 },
  )

  /**
   * Use is in the template as follow
   * :ref="setupViewportTracking(data)"
   *
   * @param data a serializable JSON object
   */
  function setupViewportTracking(data: TrackingData) {
    return function setTrackingRef(
      el: Element | ComponentPublicInstance | null,
    ) {
      if (el && el instanceof HTMLElement) {
        trackingData.set(el, data)
        targets.value.push(el)
      }

      return el
    }
  }

  function setupViewportTrackingProduct(
    data: Product,
    extra: ExtraTrackingData,
  ) {
    return setupViewportTracking(productToTrackingData(data, extra))
  }

  const { trackProductImpressionBatched, trackProductImpression } =
    useTracking()

  onMounted(() => {
    setInterval(() => {
      if (bucket.size > 0) {
        if (bucket.size === 1) {
          trackProductImpression(Array.from(bucket)[0])
        } else {
          trackProductImpressionBatched(Array.from(bucket))
        }
        bucket.clear()
      }
    }, ms)
  })

  onUnmounted(() => {
    stop()
    trackingData.clear()
  })

  return { setupViewportTracking, setupViewportTrackingProduct }
}

export type ExtraTrackingData = Partial<{
  provider: Provider
  list: string
  position?: number
  source?: string
  queryID?: string
  index?: string
  productComponent: string
  productRecoComponentPosition: number
}>
export function useClickTracking() {
  const { trackProductClick: genericTrackProductClick, trackSearchAnalytics } =
    useTracking()
  const visitorId = useVisitorId()

  function trackProductClick(
    product: Product,
    extraTrackingData: ExtraTrackingData,
  ) {
    genericTrackProductClick(productToTrackingData(product, extraTrackingData))

    const { source, queryID, index, position } = extraTrackingData
    if (source) {
      saveProductInfos({
        id: product.id,
        price: product.price.amount,
        source,
      })
    }

    if (queryID && index && position) {
      trackSearchAnalytics({
        type: 'click',
        objectId: product.listingId,
        searchQueryID: queryID,
        tokenId: visitorId,
        position: position.toString(),
        index,
      })
    }
  }

  return { trackProductClick }
}
