export default {
  colorSwatches: {
    id: 'color_swatches_label',
    defaultMessage: 'Color: {color}',
  },
  moreColors: {
    id: 'color_swatches_more',
    defaultMessage: 'more colors',
  },
} as const
