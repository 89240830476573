import { useRequestURL } from '#imports'

import type { Product } from '../models/product'

export function useProductPageLink({
  useClientSideNavigation,
}: {
  useClientSideNavigation: boolean
}) {
  const { origin } = useRequestURL()
  const buildProductPageLink = (product: Product) => {
    if (useClientSideNavigation)
      return `/${product.productPageLink.params.locale}/p/${product.productPageLink.params.slugV2}/${product.productPageLink.params.uuid}`

    return `${origin}/${product.productPageLink.params.locale}/p/${product.productPageLink.params.slugV2}/${product.productPageLink.params.uuid}`
  }

  return { buildProductPageLink }
}
