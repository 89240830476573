import { ref, watchEffect } from 'vue'

import { type HttpEndpoint, type HttpError } from '@backmarket/http-api'
import {
  addProductsToCart,
  addServiceToCart,
  addToCart as addToCartAPI,
} from '@backmarket/http-api/src/api-specs-checkout/cart/cart'
import { type GetProductResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/product'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

export function useAddToCart() {
  const idBeingAddedToCart = ref<string>()
  const addingToCart = ref<'pending' | 'idle' | 'success' | 'error'>('idle')
  const errorAddingToCart = ref<HttpError | null>()
  const { trackErrors } = useTracking()

  function useCartAPIFetch(
    cartAPIEndpoint: HttpEndpoint<unknown>,
    body: { listings: { listing_id: string }[] } | { listing_id: string },
  ) {
    const { execute, status, error } = useHttpFetch(cartAPIEndpoint, {
      immediate: false,
      body,
    })

    watchEffect(() => {
      addingToCart.value = status?.value
      errorAddingToCart.value = error?.value
      if (status.value === 'error' && error.value) {
        trackErrors({
          zone: 'bundle_add_to_cart',
          message: error.value.message,
        })
      }
    })

    return execute()
  }

  function addToCart(listingId: string) {
    idBeingAddedToCart.value = listingId

    return useCartAPIFetch(addToCartAPI, { listing_id: listingId })
  }

  async function addAllToCart(listingIds: string[]) {
    return useCartAPIFetch(addProductsToCart, {
      listings: listingIds.map((id) => ({ listing_id: id })),
    })
  }

  async function addPartnerPromoCodeToCart(
    listingPublicId: string,
    partnerPromoCode: GetProductResponse['includedServiceOffers']['partnerPromoCodes'][number],
  ) {
    const { execute } = useHttpFetch(addServiceToCart, {
      immediate: false,
      body: {
        type: 'PARTNER_PROMO_CODE',
        listingPublicId,
        offerId: partnerPromoCode.id,
        partnerName: partnerPromoCode.partnerName,
      },
    })

    return execute()
  }

  async function addMobilePlanToCart(
    listingPublicId: string,
    mobilePlanOfferId: string,
  ) {
    const { execute } = useHttpFetch(addServiceToCart, {
      immediate: false,
      body: {
        type: 'MOBILE_PLAN',
        listingPublicId,
        offerId: mobilePlanOfferId,
      },
    })

    return execute()
  }

  return {
    addToCart,
    addAllToCart,
    idBeingAddedToCart,
    addingToCart,
    errorAddingToCart,
    addMobilePlanToCart,
    addPartnerPromoCodeToCart,
  }
}
