<template>
  <div class="flex flex-col gap-12">
    <div
      v-for="spec in specifications"
      :key="spec.label"
      class="flex flex-col gap-1"
    >
      <div class="caption">
        {{
          i18n({
            id: `${spec.label}_label`,
            defaultMessage: spec.label.toLocaleUpperCase(),
          })
        }}
      </div>
      <div>
        <ul class="flex list-none flex-wrap items-center gap-4">
          <li v-for="value in spec.values" :key="value">
            <RevTag :label="value" variant="secondary" variation="outline" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevTag } from '@ds/components/Tag'

defineProps<{
  specifications: Array<{
    label: string
    values: string[]
  }>
}>()
const i18n = useI18n()
</script>
