<template>
  <div class="flex flex-col">
    <div class="text-static-default-hi body-2-bold" data-qa="productCardPrice">
      {{ price }}
    </div>

    <div v-if="priceNew" class="text-static-default-low body-2 line-through">
      {{ priceNew }}

      <span>
        {{ i18n(translations.newKeyword) }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from './PriceWithCrossedPrice.translations'

defineProps<{
  price: string
  priceNew?: string
}>()

const i18n = useI18n()
</script>
