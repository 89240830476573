<template>
  <RevButtonCard
    class="h-full overflow-hidden text-left"
    data-qa="productCard"
    :hoverable="true"
    :to="url"
  >
    <div
      ref="container"
      class="flex h-full flex-col pt-16"
      data-spec="product-card-content"
    >
      <div
        v-if="tags.length > 0"
        class="flex gap-6 px-16 pb-4"
        data-spec="tags"
      >
        <RevTag
          v-for="tag in tags"
          :key="tag"
          :label="tag"
          variant="secondary"
        />
      </div>
      <div class="flex p-16 pt-0" data-spec="main">
        <div
          class="flex grow flex-wrap content-start justify-center gap-8"
          data-spec="image+main-information"
        >
          <div
            class="flex flex-col items-center justify-center gap-2"
            data-spec="image+colors"
          >
            <RevIllustration
              :alt="imageAlt"
              :height="128"
              sizes="128px"
              :src="imageSrc"
              :width="128"
            />

            <ColorSwatches v-if="colorSwatches" :colors="colorSwatches" />
          </div>

          <div
            class="flex grow basis-[159px] flex-col items-start gap-6"
            data-spec="product-info"
          >
            <div class="flex flex-col gap-2" data-spec="titles">
              <h2>
                <span class="body-1-bold line-clamp-2">
                  {{ title }}
                </span>
                <span
                  v-if="description"
                  class="text-static-default-low body-2 line-clamp-2"
                >
                  {{ description }}
                </span>
              </h2>

              <span v-if="warranty" class="body-2">
                {{ i18n(translations.productCardWarranty, { warranty }) }}
                {{ i18n(translations.months) }}
              </span>

              <span v-if="grade" class="body-2">
                {{ i18n(translations.gradeDescription, { grade }) }}
              </span>
            </div>

            <ProductCardRatings
              v-if="reviewRating?.count && reviewRating?.average"
              :count="reviewRating.count"
              data-spec="rating"
              :score="reviewRating.average"
            />

            <div v-if="specifications && specifications.length > 0">
              <ProductSpecs :specifications="specifications" />
            </div>
            <div v-if="price" data-spec="price-information">
              <div
                v-if="withStartingFrom"
                class="text-static-default-low caption"
              >
                {{ i18n(translations.startingFrom) }}
              </div>
              <PriceWithCrossedPrice
                :price="i18n.price(price)"
                :price-new="priceNew && i18n.price(priceNew)"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="addToCartListener || withAddToCart" class="mt-auto p-16 pt-0">
        <AddToCartButton
          :id="listingId"
          :text="i18n(translations.addToCart)"
          @add-to-cart="addToCart"
        />
      </div>
    </div>
  </RevButtonCard>
</template>

<script lang="ts" setup>
import { getCurrentInstance, ref } from 'vue'

import type { LinkInternal, Price } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonCard } from '@ds/components/ButtonCard'
import { RevIllustration } from '@ds/components/Illustration'
import { RevTag } from '@ds/components/Tag'

import AddToCartButton from '../AddToCartButton/AddToCartButton.vue'
import ColorSwatches from '../ColorSwatches.vue'
import PriceWithCrossedPrice from '../PriceWithCrossedPrice.vue'
import ProductCardRatings from '../ProductCardRatings.vue'
import ProductSpecs from '../ProductSpecs.vue'

import translations from './ProductCard.translations'

export interface ProductCardProps {
  title: string
  description?: string
  imageSrc: string
  imageAlt?: string
  grade?: string
  url: string | LinkInternal
  tags?: Array<string>
  price: Price
  priceNew?: Price
  reviewRating?: {
    count: number
    average: number
  }
  listingId: string
  warranty?: number
  withStartingFrom?: boolean
  addingToCart?: boolean
  withAddToCart?: boolean
  specifications?: Array<{
    label: string
    values: string[]
  }>
  colorSwatches?: Array<{ name: string; value?: string }>
}

withDefaults(defineProps<ProductCardProps>(), {
  imageAlt: 'alt text is missing',
  description: undefined,
  grade: '',
  tags: () => [],
  priceNew: undefined,
  reviewRating: undefined,
  withStartingFrom: true,
  warranty: undefined,
  colorSwatches: () => [],
  addingToCart: false,
  withAddToCart: false,
  specifications: undefined,
})

const emit = defineEmits(['add-to-cart'])

const container = ref<HTMLDivElement | null>(null)
const i18n = useI18n()

defineExpose({ container })

const addToCartListener = getCurrentInstance()?.vnode.props?.onAddToCart

function addToCart({ status }: { status: string }) {
  emit('add-to-cart', { status })
}
</script>
